import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex justify-content-between align-items-center py-3" }
const _hoisted_2 = { class: "d-block" }
const _hoisted_3 = { class: "h4" }
const _hoisted_4 = { class: "mb-0" }
const _hoisted_5 = { class: "btn-toolbar" }
const _hoisted_6 = { class: "table-settings mb-4" }
const _hoisted_7 = { class: "row align-items-center justify-content-between" }
const _hoisted_8 = { class: "col col-md-6 col-lg-3 col-xl-4" }
const _hoisted_9 = {
  key: 0,
  class: "col-4 col-md-2 col-xl-1 pl-md-0 text-right"
}
const _hoisted_10 = { class: "card card-body border-light shadow-sm table-wrapper table-responsive pt-0" }
const _hoisted_11 = { class: "table table-hover min-h-400 align-items-center" }
const _hoisted_12 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_CountSelector = _resolveComponent("CountSelector")!
  const _component_GiftCardEntryRow = _resolveComponent("GiftCardEntryRow")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_HourglassLoader = _resolveComponent("HourglassLoader")!

  return (_openBlock(), _createBlock(_component_HourglassLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _createElementVNode("section", null, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("h2", _hoisted_3, " Gift Card Entries for " + _toDisplayString(_ctx.route.query.uid ?? _ctx.route.params.giftCardId), 1),
            _createElementVNode("p", _hoisted_4, " Find below the available gift card entries for " + _toDisplayString(_ctx.route.query.uid ?? _ctx.route.params.giftCardId), 1)
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              class: "btn btn-sm btn-outline-primary",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.RefreshData && _ctx.RefreshData(...args)))
            }, " Reload ")
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_router_link, {
                to: `/cards/${_ctx.route.params.giftCardId}/enter`,
                class: "btn btn-dark"
              }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("Create new Entry")
                ])),
                _: 1
              }, 8, ["to"])
            ]),
            (_ctx.data)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createVNode(_component_CountSelector, {
                    modelValue: _ctx.data.meta.per_page,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.meta.per_page) = $event)),
                    onUpdated: _ctx.RefreshData
                  }, null, 8, ["modelValue", "onUpdated"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createElementVNode("table", _hoisted_11, [
            _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "#"),
                _createElementVNode("th", null, "Card Type"),
                _createElementVNode("th", null, "Rate"),
                _createElementVNode("th", null, "Range/Denomination"),
                _createElementVNode("th", null, "Status"),
                _createElementVNode("th", null, "Date")
              ])
            ], -1)),
            (_ctx.data)
              ? (_openBlock(), _createElementBlock("tbody", _hoisted_12, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.data.entries, (dt, index) => {
                    return (_openBlock(), _createBlock(_component_GiftCardEntryRow, {
                      key: index,
                      dt: dt,
                      onDelete: _ctx.RefreshData,
                      onStatusChanged: ($event: any) => (dt.is_active = !dt.is_active)
                    }, null, 8, ["dt", "onDelete", "onStatusChanged"]))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.data)
            ? (_openBlock(), _createBlock(_component_Pagination, {
                key: 0,
                modelValue: _ctx.data.meta.page,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.meta.page) = $event)),
                currentPage: _ctx.data.meta.page,
                totalRecord: _ctx.data.meta.count,
                dataCount: _ctx.data.meta.per_page,
                onUpdated: _ctx.RefreshData
              }, null, 8, ["modelValue", "currentPage", "totalRecord", "dataCount", "onUpdated"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}